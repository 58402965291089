import React, { useState ,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FiMoreVertical} from 'react-icons/fi'
import './index.css'
import {IoCopyOutline,IoCopy} from "react-icons/io5"
import {WhatsappShareButton,WhatsappIcon,TelegramShareButton,TelegramIcon} from 'react-share';
import Cookies from 'js-cookie';
const modelItems=[{
    itemName:"Unfollow",
    id:3,
   
},{
    itemName:"Go Post",
    id:4,

},{
    itemName:"Go to Account",
    id:5,
    
}]

const ModelItems=(props)=>{

    const {modelItemText}=props
    const {itemName}=modelItemText
   
    return(
        <div className='model-text-div'>
             <p className='model-report-text'>{itemName}</p>
        </div>
    )
}

function MoreModel(props) {
  let jwtToken1=""
  const {selectedPostId1,caption,postId,designerId,loginUser}=props
  const [show, setShow] = useState(false);
console.log(designerId,loginUser.designer_id,"designerId,loginUser")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [copyIcon,setCopyIcon]=useState(false)
  const copyTourUrl=()=>{
    navigator.clipboard.writeText(`${window.location.origin}/sharedPost:${postId}`)
    setCopyIcon(true)
  }
const onclickPin=()=>{
  localStorage.setItem("pinPost",JSON.stringify(selectedPostId1))
}
const changePrivacy=async(event)=>{
  console.log(event.target.value)
   jwtToken1=Cookies.get("jwt_token")
  
  const privacy=event.target.value
  const apiUrl=`https://venkatsai113-1gbj.onrender.com/postPrivacyChange/?privacy=${privacy}&designerId=${loginUser.designer_id}`
  const options={
    method:"POST",
    header:{
      "Content-Type":"Application/json",
      
    }
  }
  const response=await fetch(apiUrl,options)
  const data=await response.json()
  console.log(data)

}
const onDeletePost=async()=>{


  let text = "Are you sure, You want to delete the Post";
  if (window.confirm(text) == true) {
    console.log("Hello")
    const apiUrl=`https://venkatsai113-1gbj.onrender.com/deletePost?postId=${postId}` 
  const options={
    method:"POST",
    header:{
      "Content-Type":"Application/json",
      "authorization":`Bearer ${jwtToken1}`
    }
  }
  const response=await fetch(apiUrl,options)
  const data=await response.json()
  if(response.ok===true){
    window.location.reload();
  }

  } else {
    console.log("Hiii")
  }
}
  return (
    <>
     
      <FiMoreVertical onClick={handleShow}/>

      <Modal show={show} onHide={handleClose}>
       
        <Modal.Body>
        {loginUser.designer_id==designerId?<div className='model-text-div1'>
             <p className='model-report-text1' >Privacy Settings</p>
             <select className="form-control" onChange={changePrivacy}>
              <option>Select</option>
              <option value="Public">Public</option>
              <option value="Private">Private</option>
             </select>
        </div>:null}
        {loginUser.designer_id==designerId?<div className='model-text-div1'>
             <p className='model-report-text' onClick={onDeletePost}>Delete Post</p>
             
        </div>:null}
            <div className='feed-model-container'>
               {modelItems.map(eachItem=>
                <ModelItems modelItemText={eachItem} key={eachItem.id}/>)}
            </div>
            <div className='model-text-div'>
             <p className='model-report-text' onClick={onclickPin}>Pin to your Profile</p>
        </div>
       
            <div className='d-flex flex-row justify-content-around'>
            {copyIcon? <p className='copy-icon' onClick={copyTourUrl}> <IoCopy/></p>:<p className='copy-icon' onClick={copyTourUrl}> <IoCopyOutline/></p>} 
            {/* <div>
      <FacebookShareButton
        url={`${window.location.origin}/sharedPost:${selectedPostId1}`}
        quote={'Dummy text!'}
        hashtag="#muo"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

    </div> */}
    <div>
      <WhatsappShareButton
        url={`${window.location.origin}/sharedPost:${selectedPostId1}`}
        quote={'Dummy text!'}
        hashtag="#muo"
        title={caption}
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      
    </div>
    <div>
      <TelegramShareButton
        url={`${window.location.origin}/sharedPost:${selectedPostId1}`}
        quote={'Dummy text!'}
        hashtag="#muo"
      >
        <TelegramIcon size={32} round />
      </TelegramShareButton>
      
    </div>
    </div>
        </Modal.Body>
       
      </Modal>
    </>
  );
}

export default MoreModel