import './index.css'
import {FiSend} from 'react-icons/fi'
import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import {Link} from 'react-router-dom'

const ChatContainer=(props)=>{
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('messages')
      .onSnapshot((snapshot) => {
        const messages = snapshot.docs.map((doc) => doc.data());
        setMessages(messages);
        console.log(messages)
      });
    return () => {
      unsubscribe();
    };
  }, []);
  const messageText=(event)=>{
    console.log(event.target.value)
  }
      const {chatProfileDetails}=props
      const {desigener_name,logo,designer_id}=chatProfileDetails
      const sendMessage = () => {
        firebase
          .firestore()
          .collection("messages")
          .add({
            message: newMessage,
            sender: firebase.auth().currentUser.uid,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
        setNewMessage('');
      };
      const viewProfile=()=>{
        localStorage.setItem("designerProfileId",designer_id) 
        localStorage.getItem("")
      }
        return(
            <div className="chatlist-container1">
            <Link to="/designerProfile" className="nav-link"><div className='chat-profile-div' onClick={viewProfile}>
              <img alt="" src={`https://venkatsai113-1gbj.onrender.com/${logo}`} className="profile-image-chats1"/>
             <div className='d-flex flex-column'>
              <label className="suggestion-profile-name ml-3">{desigener_name}</label>
              <label className="suggestion-profile-desigenaton ml-3">online</label>
              </div>
              </div></Link>
              <div className='messages-container3'>    
        {messages.map((message) => (
          <p className='sending-messages'>{message.message} </p>
        ))}
                <p className='chat-date'>Feb 26 2023</p>
                <p className='receiving-messages'>I need 2BHK Interior Design</p>
              </div>
              <div className='chat-input-container'>
               <input type="text" className='message-input' placeholder='Message...'  value={newMessage}  onChange={(e) => setNewMessage(e.target.value)}/>
               <button type="button" className='msg-send-btn' onClick={sendMessage}><FiSend/></button>
              </div>
             </div> 
        )
}
export default ChatContainer