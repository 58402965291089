import React, { useState, useEffect } from 'react';
import firebase from 'firebase';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('messages')
      .onSnapshot((snapshot) => {
        const messages = snapshot.docs.map((doc) => doc.data());
        setMessages(messages);
      });

    return () => {
      unsubscribe();
    };
  }, []);

  const sendMessage = () => {
    firebase
      .firestore()
      .collection('messages')
      .add({
        message: newMessage,
        sender: firebase.auth().currentUser.uid,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });

    setNewMessage('');
  };

  return (
    <div>
      <h1>Chat</h1>
      <ul>
        {messages.map((message) => (
          <li key={message.id}>
            {message.message}
          </li>
        ))}
      </ul>
      <input
        type="text"
        placeholder="New message"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
      />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
};

export default Chat;