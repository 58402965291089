import {Component} from 'react'
import './index.css'
import {Container,Row,Col} from 'react-bootstrap'
import {Example1} from './FilterModel'
import Sidebar from '../Sidebar'
import TopNavbar from '../Home/topNavbar'
import BottomNavbar from '../Home/bottomNavbar'
import ProdutsList from './exploreImages'
import {GrGallery} from 'react-icons/gr'
import {HiOutlinePhotograph} from 'react-icons/hi'
import {MdSlowMotionVideo} from 'react-icons/md'
import {TbRotate360} from 'react-icons/tb'
import DesktopTabItems from './desktopTabItems'
import MobileViewTabs from './mobileViewTabItems'
import Cookies from 'js-cookie'

const desktopTabItems=[{
    tabicon:<GrGallery/>,
    displaidText:"All",
    TabId:"All",
},
{
    tabicon:<HiOutlinePhotograph/>,
    displaidText:"Photos",
    TabId:"image",
}
,
{
    tabicon:<MdSlowMotionVideo/>,
    displaidText:"Videos",
    TabId:"video",
},
{
    tabicon:<TbRotate360/>,
    displaidText:"360 Virtual Toure",
    TabId:"virtualTourImage",
},]
let jwtToken=""
class Explore extends Component{
    state={productDetails:[],userSearch:"",desktopTabItemsId:"All"}
    componentDidMount=()=>{
        jwtToken=Cookies.get("jwt_token")
        const exploareData=async()=>{
            const feedUrl="https://venkatsai113-1gbj.onrender.com/feedData"
            const options={
                method:"GET",
                headers:{
                    "Content-Type":"Application/json",
                    "Authorization":`Bearer ${jwtToken}`
                }
               
            }
            const response=await fetch(feedUrl,options);
            const data=await response.json();
            this.setState({productDetails:data})
            console.log(data,"feedData")
        }
        exploareData()
       
    }
   
    onSearch=async(event)=>{
        const userSearchInput=event.target.value
       this.setState({userSearch:userSearchInput})
       const apiUrl=`https://venkatsai113-1gbj.onrender.com/exploreSearch?searchInput=${userSearchInput}`
       const options={
        method:"POST",
        headers:{
            "Content-Type":"Application/json",
            "Authorization":`Bearer ${jwtToken}`
        },
       }
       const response=await fetch(apiUrl,options)
       const data=await response.json()
       console.log(data)
       this.setState({productDetails:data})
    }
    // filteredProjects=()=>{
    //     const {productDetails,desktopTabItemsId}=this.state
    //    const activetab1= productDetails.filter(eachItem=>
    //         eachItem.postType==="virtualTourImage")
    //         if(desktopTabItemsId==="virtualTourImage"){
    //             return(productDetails)
    //         }
    //         return(activetab1)
    // }
    updateActiveTabId=async(TabId)=>{
        this.setState({desktopTabItemsId:TabId})
        if(TabId==="All"){
            const jwtToken=Cookies.get("jwt_token")
            const feedUrl="https://venkatsai113-1gbj.onrender.com/feedData"
            const options={
                method:"GET",
                headers:{
                    "Content-Type":"Application/json",
                    "Authorization":`Bearer ${jwtToken}`
                }
               
            }
            const response=await fetch(feedUrl,options);
            const data=await response.json();
            this.setState({productDetails:data})
            console.log(data,"feedData")
        }
        else{
            let jwtToken=Cookies.get("jwt_token")
            const feedUrl=`https://venkatsai113-1gbj.onrender.com/filterdData/?type=${TabId}`
            const options={
                method:"GET",
                headers:{
                    "Content-Type":"Application/json",
                    "Authorization":`Bearer ${jwtToken}`
                }
               
            }
            const response=await fetch(feedUrl,options);
            const data=await response.json();
            this.setState({productDetails:data})
            console.log(data,"feedData")
        }
       

    }
    
    
    render(){
        const {productDetails,userSearch,desktopTabItemsId}=this.state
        // const resultProducts=productDetails.filter(eachItem =>
        //     eachItem.name.toLowerCase().includes(userSearch))
        //     const activetab=this.filteredProjects()
        
        return(
            <>
            <Sidebar/>
            <TopNavbar/>
            <div className='explore-bg-container'>
            <Container fluid>
                <Row>
                    <Col md={12}>
                       <h6>Explore</h6>
                        <div className='filter-row-div'>
                        <input type="search" className='form-control mr-4 mt-5' onChange={this.onSearch} placeholder="Search..."/>
                        {/* <Example1/> */}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <div className='filter-icons-desktop-view'>
                        {desktopTabItems.map(eachlgtabItem=>
                            <DesktopTabItems  desktopTabList={eachlgtabItem} key={eachlgtabItem.TabId} updateActiveTabId={this.updateActiveTabId} desktopTabItemsId={desktopTabItemsId===eachlgtabItem.TabId}/>)}
                       </div>
                       {/* <div className='filter-icons-mobile-view'>
                       {desktopTabItems.map(eachlgtabItem=>
                            <MobileViewTabs desktopTabList={eachlgtabItem} key={eachlgtabItem.TabId} updateActiveTabId={this.updateActiveTabId} desktopTabItemsId={desktopTabItemsId===eachlgtabItem.TabId}/>)}
                       </div> */}
                    </Col>
                    <div className='emtyDiv'>
                    </div>
                    {productDetails.map(eactProduct=>
                    <ProdutsList productDetails={eactProduct} key={eactProduct.postId}/>)}  
                </Row>
            </Container>
            <BottomNavbar/>
        </div>
        </>
        )
    }
}

export default Explore