import {BsTrash} from 'react-icons/bs'
import {GrEdit} from 'react-icons/gr'
import Cookies from 'js-cookie'
import { useEffect,useState } from 'react'
import {TiTick} from 'react-icons/ti'
let jwtToken=""
const SpaceProductsTable=(props)=>{
  useEffect(()=>{
    jwtToken=Cookies.get("jwt_token")
  })
    const {spaceProducts,deleteSpaceProduct,editQty}=props
    const {title,quentity,productSize,projectSpaceProductId}=spaceProducts
    const [editStatus,setEditStatus]=useState(true)
    const [quentityChange,setQuentityChange]=useState("")
    const deleteSpaceProducts=()=>{
      deleteSpaceProduct(projectSpaceProductId)
     
    }
    const onChangeQuentity=(event)=>{
      setQuentityChange(event.target.value)   
    }
    const editQuentity=()=>{
    
      setEditStatus(false)
    }
    const changeQuentity=()=>{
      editQty(projectSpaceProductId,quentityChange)
      setEditStatus(true)
    }
    
    return(
        <tr>
        {/* <th scope="row">{i}</th> */}
        <td>{title}</td>
        <td>{productSize}</td>
        {editStatus?  <td>{quentity} <GrEdit style={{fontSize:"15px"}} onClick={editQuentity}/></td>:  <td><input type="number" style={{width:"50px",borderRadius:"3px"}} onChange={onChangeQuentity}/> <TiTick style={{fontSize:"25px"}} onClick={changeQuentity} /></td>}
      
        <td><BsTrash onClick={deleteSpaceProducts} style={{fontSize:"20px"}}/></td>
      </tr>
    ) 
}
export default SpaceProductsTable