import { Component } from "react";
import {Link} from 'react-router-dom'
import './index.css'
import Sidebar from '../Sidebar'
import TopNavbar from "../Home/topNavbar";
import BottomNavbar from "../Home/bottomNavbar";
import  ChatContainer from './chatContainer'
import Cookies from "js-cookie";
import ChatUsers from './ChatConfig'
let jwtToken=""
class Chats extends Component{

  state={chatProfileDetails:"",messageUser:[]}
  componentDidMount=()=>{
    jwtToken=Cookies.get("jwt_token")
    const chatUsers=async()=>{
      const suggestionUrl="https://venkatsai113-1gbj.onrender.com/relatedUsers"
      const options={
          method:"GET",
          headers:{
              "Content-Type":"Application/json",
              "Authorization":`Bearer ${jwtToken}`
          }
      }
      const response=await fetch(suggestionUrl,options);
      const data=await response.json();
      this.setState({messageUser:data})
    }
    chatUsers()
    const pathName=window.location.pathname
    const profileId=parseInt(pathName.split(":")[1]) 
    if(/^-?\d+(\.\d+)?$/.test(profileId)){
      console.log(profileId,"wefasasfsdfsdfsdfsdfsdfsd")
      const chatProfile=async()=>{
        const apiUrl=`https://venkatsai113-1gbj.onrender.com/chatProfile?profileId=${profileId}`
        const options={
          method:"POST",
          headers:{
            "Content-Type":"Application/json",
            "Authorization":`Bearer ${jwtToken}`
          }
        }
        const response=await fetch(apiUrl,options)
        const data=await response.json()
        console.log(data)
       
        if(response.ok===true){
          this.setState({chatProfileDetails:data})
        }
       
      }
      chatProfile()
    
    }
  
  
  }
    render(){
      const {chatProfileDetails,messageUser}=this.state
        return(
            <div className="d-flex flex-row">
              <Sidebar/>
              <TopNavbar/>
              <BottomNavbar/>
              <div className="chats-bg-container">
                <div className="chat-list-div">
                  {messageUser.map(eachItem=>
                   <ChatUsers chatUsers={eachItem}/>
                )}
                  {/* <div className="chat-card-container">
                    <div className="d-flex flex-row">
                    <img className="profile-image-chats" src="https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg"/>
                    <div className="d-flex flex-column">
                    <label className="suggestion-profile-name">Venkat</label>
                    <label className="suggestion-profile-desigenaton">Active 5 Minuties ago</label>
                    </div>
                    </div>
                    <label className="chat-date-text">06:30 pm</label>
                    <label className="active-text"> </label>
                  </div> */}
                </div>
              </div>
              <ChatContainer chatProfileDetails={chatProfileDetails}/>
            </div>
        )
    }
}
export default Chats