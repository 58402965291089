import {Link} from 'react-router-dom'
import {Col} from 'react-bootstrap'

const ProdutsList=(props)=>{
    const {productDetails}=props
    const {thumbnail,postId,designerId}=productDetails 
    const thumbnailUrl=thumbnail.split(",")[0]
    const splitedUrl=thumbnailUrl.split(".")[1]
    const explorePost=()=>{
        localStorage.setItem("designerProfileId",designerId)
    }
    return(
        <Col sm={6} md={4} lg={3}>
        <div className='product-card-container'>
            {splitedUrl=="mp4"? <Link to={`/designerprofilePosts:${postId}`}><video onClick={explorePost} src={`https://venkatsai113-1gbj.onrender.com/${thumbnail}`} alt="product" className='image-style'/></Link>: <Link to={`/designerprofilePosts:${postId}`}><img onClick={explorePost} src={`https://venkatsai113-1gbj.onrender.com/${thumbnail}`} alt="product" className='image-style'/></Link>}
           
        </div>
        </Col>
    )
}
export default ProdutsList