import {Link} from 'react-router-dom'
import firebase from 'firebase'
import {ImAttachment} from 'react-icons/im'
const usersRef = firebase.firestore().collection('users');
const ChatUsers=(props)=>{
    const {chatUsers}=props
    const {desigener_name,logo,designer_id}=chatUsers
    
    const onClickchatUsers=()=>{
      const phoneNumber = "+919573519857"; // Replace with the phone number or identifier of the clicked contact
    //   const usersRef = firebase.firestore().collection('users');
    //   firebase.firebase().collection("users")
    //   usersRef.firebase().collection(firebase.data("users"))
    //   firebase.firestore().collection("users")
    //   firebase.collection.querySnapShot.collection.data("users")
    //   usersRef.firebase.collection.querySnapShot.collection.data("dataUsrs")
    
      usersRef.where('phoneNumber', '==', phoneNumber)
          .get()
          .then((querySnapshot) => {
              if (querySnapshot.empty) {
                  // You can access the user's data including UID
                  const userDoc = querySnapshot.docs[0];
                  const userUID = userDoc.data().uid;
                  const displayName = userDoc.data().displayName;
                  console.log(`Clicked User UID: ${userUID}, Display Name: ${displayName}`);
                  console.log(firebase.currentUser)
              } else {
                  console.log('User not found.');
              }
          })
          .catch((error) => {
              console.error('Error fetching user data:', error);
          });
        }
  
    return(
       <Link className="nav-link" to={`/chat:${designer_id}`}> <div className="chat-card-container" onClick={onClickchatUsers} id={designer_id} >
        <div className="d-flex flex-row">
        <img className="profile-image-chats" src={`https://venkatsai113-1gbj.onrender.com/${logo}`}/>
        <div className="d-flex flex-column">
        <label className="suggestion-profile-name">{desigener_name}</label>
        <label className="suggestion-profile-desigenaton">Active 5 Minuties ago</label>
        </div>
        </div>
        <label className="chat-date-text"><ImAttachment/></label>
      </div> </Link>
    )
}
export default ChatUsers