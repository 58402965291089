import { Component } from "react";
import './editProfile.css'
import Sidebar from '../Sidebar'
import BottomNavbar from '../Home/bottomNavbar'
import ProfileTopNavbar from '../Account/profileTopNav'
import Cookies from "js-cookie"
import { Container,Row,Col} from "react-bootstrap";

let jwtToken=""
class NotificationForm extends Component{
    state={profileDetails:"",name:"",logo:"",emailId:"",IFSCCODE:"",branch:"",bankName:"",mobile:"",accountNumber:""}
    onChangeValues=(event)=>{
        const {name,value}=event.target
        this.setState({[name]:value})
    }
    componentDidMount=async()=>{
         jwtToken=Cookies.get("vendorToken")
        const url="https://venkatsai113-1gbj.onrender.com/vendorProfile";
        const options={
            headers:{
                "authorization":`Bearer ${jwtToken}`
            },
            method:"GET"
        }
        const response=await fetch(url,options);
        console.log(response)
        if(response.ok===true){
            const fetchedData=await response.json();

            // this.setState({profileDetails:fetchedData[0]})
            console.log(fetchedData[0].name)
            this.setState({name:fetchedData[0].name})
            this.setState({emailId:fetchedData[0].emailId})
            this.setState({IFSCCODE:fetchedData[0].IFSCCODE})
            this.setState({branch:fetchedData[0].branch})
            this.setState({bankName:fetchedData[0].bankName})
            this.setState({mobile:fetchedData[0].mobile})
            this.setState({accountNumber:fetchedData[0].accountNumber})
            
        }
    }
    render(){
        // const {profileDetails}=this.state
        const {name,logo,emailId,IFSCCODE,branch,bankName,mobile,accountNumber}=this.state
        
        return(
            <div className="home-top-navbar-container">
               <ProfileTopNavbar/>
            <div>
            <div className="d-flex flex-row">
                <Sidebar/>
                <div>
                    <div className="emty-div"></div>
            <div className="saved-feed-container1">
                <div className="saved-profile-div">
                    <div className="d-flex flex-row">
                    <img  src={`https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500`} alt="profile" className="profile-image-profilepic"/>
                    <p>Hello</p>
                    </div>
                   
                </div>
                {/* <div className="saved-profile-div">
                <Link to="/editprofile"><Button variant="primary"  size="sm">Edit Profile</Button></Link>
                </div> */}
                <div style={{width:"100vw" }}>
                <Container>
                <Row className=" mt-3">
                    <Col lg={4}>
                    <p className="product">Email ID</p>
                    <input type="email" value={emailId} name="emailId" className="form-control" onChange={this.onChangeValues}/>
                    </Col>
                    <Col lg={4}>
                    <p className="product">Contact</p>
                    <input type="number" value={mobile} name="mobile" className="form-control" onChange={this.onChangeValues}/>
                   
                    </Col>
                    <Col lg={4}>
                    <p className="product">Bank Name</p>
                    <input type="text" value={bankName} name="bankName" className="form-control" onChange={this.onChangeValues}/>
                    
                    </Col>
                    <Col lg={4}>
                    <p className="product mt-3">Account Number</p>
                    
                    <input type="number" value={accountNumber} name="accountNumber" className="form-control" onChange={this.onChangeValues}/>
                    </Col>
                    <Col lg={4}>
                    <p className="product mt-3">Branch</p>
                  
                    <input type="text" value={branch} className="form-control"name="branch" onChange={this.onChangeValues}/>
                    </Col>
                    <Col lg={4}>
                    <p className="product mt-3">IFSC Code</p>
                    <input type="text" value={IFSCCODE} className="form-control" name="IFSCCODE" onChange={this.onChangeValues}/>
                   
                    </Col>
                </Row>
              </Container>
                </div>
                <div>
                </div>
            </div>
            <div className="d-flex flex-row">
            </div>
            </div>
            </div>
            </div>
        <BottomNavbar/>
            </div>
        )
    }
}
export default NotificationForm