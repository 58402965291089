import {Link} from 'react-router-dom'

const SavedFileCard=(props)=>{
    const {cardItem}=props
    const {thumbnail,postId}=cardItem
   const firstImage=thumbnail.split(",")
   const displayedImage=firstImage[0]
   const splitDisplayedImage=displayedImage.split(".")[1]
   console.log(displayedImage,"-p0oiuythgr")
   const onPostClick=()=>{
    console.log(postId)
   }
    return(
        <>
        {splitDisplayedImage=="mp4"?<Link to={`/profilePosts:${postId}`}><video  src={`https://venkatsai113-1gbj.onrender.com/${displayedImage}`} onClick={onPostClick} autoPlay loop muted  className="profile-post-gridView"/></Link>:<Link to={`/profilePosts:${postId}`}><img src={`https://venkatsai113-1gbj.onrender.com/${displayedImage}`} onClick={onPostClick} className="profile-post-gridView"/></Link>}
        
        
        </>
    )
}
export default SavedFileCard