import './index.css'
import { withRouter } from 'react-router-dom'
const SuggestionProfiles=(props)=>{
    const {suggestionProfilesList}=props
    const {logo,desigener_name,designer_id,profileDescription,status}=suggestionProfilesList
    const onClickProfileData=()=>{
      console.log(designer_id)
      localStorage.setItem("designerProfileId",designer_id)
      const {history}=props
      history.push("/designerProfile")
    }
    return(
        <div className="suggestion-profile-container" onClick={onClickProfileData}>
              <div className="suggestion-profile">
              <img alt="" src={`https://venkatsai113-1gbj.onrender.com/${logo}`} className="suggestion-profile-image"/>
              <div className="profile-name-designation">
                <label className="suggestion-profile-name">{desigener_name}</label>
                <label className="suggestion-profile-desigenaton">interior Desigener</label>
              </div>
              </div>
              <p className="follow-text">follow</p>

               </div>
    )
}
export default withRouter(SuggestionProfiles)