import { Component } from "react";
import './index.css'
import Sidebar from '../Sidebar'
import BottomNavbar from '../Home/bottomNavbar'
import ProfileTopNavbar from '../Account/profileTopNav'
import {Container,Row,Col} from 'react-bootstrap'
// import StandardImageList from './uploadedimages'
import Cookies from "js-cookie"

let postData=""
let jwtToken=""
class ProjectAcceptanceForm extends Component{
    state={profileDetails:"",profilePosts:[]}
    componentDidMount=async()=>{
        
         jwtToken=Cookies.get("jwt_token")
        const url="https://venkatsai113-1gbj.onrender.com/profileData";
        const options={
            headers:{
                "authorization":`Bearer ${jwtToken}`
            },
            method:"GET"
        }
        const response=await fetch(url,options);
        console.log(response)
        if(response.ok===true){
            const fetchedData=await response.json();
            this.setState({profileDetails:fetchedData});
            console.log(fetchedData);
        }
        const postApiUrl="https://venkatsai113-1gbj.onrender.com/profileAllposts"
        const postOptions={
            headers:{
                "Content-Type":"Application/json",
                "authorization":`Bearer ${jwtToken}`
            },
            method:"GET"
        }
        const postResponse=await fetch(postApiUrl,postOptions)
         postData=await postResponse.json()
        if(response.ok===true){
            this.setState({profilePosts:postData});

        }
        console.log(postData)
    }
    allPosts=()=>{
        this.setState({profilePosts:postData});
    }
    acceptsnceButton=()=>{
        const {history}=this.props
        history.replace("/")
        
    }
    render(){
        const {profileDetails,}=this.state
        const { desigener_name,logo}=profileDetails
        console.log(logo)
        return(
            <div className="home-top-navbar-container">
               <ProfileTopNavbar/>
            <div>
            <div className="d-flex flex-row">
                <Sidebar/>
                <div>
                    <div className="emty-div"></div>
            <div className="saved-feed-container ">
                <div className="saved-profile-div1">
                    <div className="project-designer-div">
                    <img src={`https://venkatsai113-1gbj.onrender.com/${logo}`} alt="profile" className="profile-image-profilepic"/>
                    <div>
                    <p  className="saved-profile-name">{desigener_name}</p>
                    <p  className="saved-profile-name">Project</p>

                    </div>
                    </div>
                </div>
                <div className="accept-details-div">
                    <Container fluid>
                        <Row>
                            <Col md="4">
                                <p className="suggestion-profile-name">City</p>
                                <input type="text" className="form-control" placeholder="Ex: Hyderabad"/>
                            </Col>
                            <Col md="4">
                            <p className="suggestion-profile-name">Locality or Area</p>
                                <input type="text" className="form-control" placeholder="Ex: Banjara Hills"/>
                            </Col>
                            <Col md="4">
                            <p className="suggestion-profile-name">Property Type</p>
                                <input type="text" className="form-control" placeholder="Ex: Residential"/>
                            </Col>
                            <Col md="4">
                            <p className="suggestion-profile-name">Unit Type</p>
                                <input type="text" className="form-control" placeholder="Ex: Independent House"/>
                            </Col>
                            <Col md="4">
                            <p className="suggestion-profile-name">BHK Type</p>
                                <input type="text" className="form-control" placeholder="Ex: Independent House"/>
                            </Col>
                            <Col md="4">
                            <p className="suggestion-profile-name">Min Budget</p>
                                <input type="text" className="form-control" placeholder="Ex: Independent House"/>
                            </Col>
                            <Col md="4">
                            <p className="suggestion-profile-name">Max Budget</p>
                                <input type="text" className="form-control" placeholder="Ex: Independent House"/>
                            </Col>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-around mt-3">
                                <button className="btn btn-danger">Reject</button>
                                <button className="btn btn-primary" onClick={this.acceptsnceButton}>Accept</button>
                                </div>
                        </Row>
                    </Container>
                </div>
                <div>
                </div>
            </div>
            </div>
            </div>
            </div>
        <BottomNavbar/>
            </div>
        )
    }
}
export default ProjectAcceptanceForm