import {Component} from 'react'
import Accordion from 'react-bootstrap/Accordion';

const iniialInformationItems=[]
const Information=(props)=>{
    const {informationItems}=props
    const {name,desc}=informationItems
    return(
        <p className="information"><strong>{name}</strong>: {desc}</p>
    )
}
class ProductView extends Component{
    state={informationItems:iniialInformationItems}
    render(){
        // const {informationItems,}=this.state
        const {singleProductData}=this.props
        const {tour_description,category,designStyle,duration,location,occupancy,propertySize,tags}=singleProductData
        return(
            <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Description</Accordion.Header>
        <Accordion.Body>
        {tour_description}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Additional Information</Accordion.Header>
        <Accordion.Body>
            <div className='informationDiv'>
                {/* {informationItems.map(eachItem=>
                <Information informationItems={eachItem} key={eachItem.id}/>
                    )} */}
                     <p className="information"><strong>category</strong>: {category}</p>
                     <p className="information"><strong>Design Style</strong>: {designStyle}</p>
                     <p className="information"><strong>duration</strong>: {duration}</p>
                     <p className="information"><strong>location</strong>: {location}</p>
                     <p className="information"><strong>occupancy</strong>: {occupancy}</p>
                     <p className="information"><strong>propertySize</strong>: {propertySize}</p>
                     <p className="information"><strong># Tags</strong>: {tags}</p>
                     {/* <p className="information"><strong>quantity</strong>: {quantity}</p>
                     <p className="information"><strong>shipping Charges</strong>: ₹ {shippingCharges}</p>
                     <p className="information"><strong>Tax</strong>: {tax} %</p> */}
                     {/* <p className="information"><strong>quantity</strong>: {quantity}</p>
                     <p className="information"><strong>shipping Charges</strong>: ₹ {shippingCharges}</p> */}
            </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        )
    }
}
export default ProductView;